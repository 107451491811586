<template>
  <a-card class="page-container">
    <h1 class="h1_title">KPI设置</h1>
    <div class="form-model">
      <a-form-model v-bind="formItemLayout" :model="form" :rules="rules" ref="ruleForm">
        <p class="main-title">基础设置</p>
        <a-form-model-item label="KPI名称:" prop="name">
          <a-input v-model="form.name" :maxLength="30"/>
        </a-form-model-item>
        <a-form-model-item label="选择KPI考核时间:" prop="date_value">
          <a-range-picker
            :placeholder="['开始时间', '结束时间']"
            show-time
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            inputReadOnly
            @change="onChangeDate"
            style="width:100%"
            v-model="form.date_value"/>
        </a-form-model-item>

        <a-divider/>
        <p class="main-title1">活动模板设置</p>
        <a-checkbox
          v-model="form.modal_checked"
          style="padding-left: 100px"
          @change="changeModalChecked"
        >
          是否使用模板
        </a-checkbox>
        <a-form-model-item label="模板限制:" prop="open_type" v-if="form.modal_checked">
          <a-radio-group
            v-model="form.open_type"
            @change="changeOpen_type"
          >
            <a-radio :value="0"> 不限制</a-radio>
            <a-radio :value="1"> 指定模板</a-radio>
          </a-radio-group>
          <a-button @click="onSelectTemplate" style="margin-left: 12px;"
                    v-if="form.open_type == 1 && !selectedRowKeys.length">
            选择模板
          </a-button>
          <span v-if="form.open_type == 1 && selectedRowKeys.length">
           <a-button type="link" style="margin-right: 10px">已选择{{ selectedRowKeys.length || 0 }}个模板</a-button>
          <a-icon type="file-search" style="font-size: 18px;cursor: pointer" @click="handelBtn(1)"/>
          <a-icon type="form" style="padding: 0 14px;font-size: 18px;cursor: pointer" @click="handelBtn(2)"/>
          <a-icon type="delete" style="font-size: 18px;cursor: pointer" @click="handelBtn(3)"/>
         </span>
        </a-form-model-item>

        <a-divider/>
        <p class="main-title1">活动KPI设置</p>
        <a-form-model-item>
          <div class="item" v-for="(item, index) in form.activitiesList" :key="index">
            <div class="item_box">
              <span>{{ index + 1 }}. 指标</span>
              <a-select
                v-model="item.name"
                placeholder="请选择指标"
                style="width: 200px"
              >
                <a-select-option
                  v-for="d in indicatorsList"
                  :value="d.id"
                  :key="d.id"
                  :disabled="disabledName.includes(d.name)">
                  {{ d.name }}
                </a-select-option>
              </a-select>
              ，
            </div>
            <div class="item_box item_box1">
              <span>考核期间大于</span>
              <a-input-number style="width: 200px" id="inputNumber" v-model="item.value" :precision="0" :min="1"
                              :max="99990000"/>
            </div>
            <a-icon type="minus-circle" style="font-size: 26px;color: red;padding-left: 8px;cursor: pointer;"
                    v-if="form.activitiesList.length >= 2 && index != 0" @click="removeItem(0,index)"/>
          </div>
          <a-button type="dashed" icon="plus" block style="margin-left: 150px;margin-top: 20px;margin-bottom: 20px"
                    @click="addBtn(0)">添加
          </a-button>
        </a-form-model-item>

        <a-divider/>
        <p class="main-title1">经销商KPI设置</p>
        <a-form-model-item>
          <div class="item" v-for="(item, index) in form.dealerList" :key="item.key">
            <div class="item_box">
              <span>{{ index + 1 }}. 指标</span>
              <a-select
                v-model="item.name"
                placeholder="请选择指标"
                style="width: 200px"
              >
                <a-select-option v-for="d in indicatorsList" :key="d.id" :value="d.id"
                                 :disabled="disabledDealer.includes(d.name)">
                  {{ d.name }}
                </a-select-option>
              </a-select>
              ，
            </div>
            <div class="item_box item_box1">
              <span>考核期间大于</span>
              <a-input-number style="width: 200px" id="inputNumber" v-model="item.value" :precision="0" :max="99990000"
                              :min="1"/>
            </div>
            <a-icon type="minus-circle" style="font-size: 26px;color: red;padding-left: 8px;cursor: pointer;"
                    v-if="form.dealerList.length >= 2 && index != 0" @click="removeItem(1,index)"/>
          </div>
          <a-button type="dashed" icon="plus" block style="margin-left: 150px;margin-top: 20px;margin-bottom: 20px"
                    @click="addBtn(1)">添加
          </a-button>
        </a-form-model-item>

        <a-divider/>
        <a-form-model-item>
          <a-checkbox @change="onChange" style="padding-left: 100px">提交并再填一份</a-checkbox>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="onSubmit" style="margin-left: 40px;">
            提交
          </a-button>
          <a-button style="margin-left: 40px;" @click="handelCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <!-- 选择模板 -->
    <a-modal
      v-model="templateVisible"
      title="选择模板"
      width="70%"
      @ok="handleOktTemplateVisible"
      @cancel="handleCancel"
    >
      <a-form-model
        v-bind="layout"
        ref="search_form"
        :model="search_form"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="模板名称"
            >
              <a-input
                placeholder="请输入模板名称"
                v-model="search_form.module_name"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="分类"
            >
              <a-select
                v-model="search_form.module_tag_id"
                placeholder="请选择"
                allow-clear
              >
                <a-select-option
                  v-for="item in module_tag_list"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="创作人"
            >
              <a-input
                placeholder="请输入创作人"
                v-model="search_form.creator"
              />
              <!--              <a-select
                              v-model="search_form.creator"
                              placeholder="请选择"
                              allow-clear
                            >
                              <a-select-option
                                v-for="item in creator_list"
                                :key="item.id"
                              >
                                {{ item.name }}
                              </a-select-option>
                            </a-select>-->
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="品牌" placeholder="请选择品牌">
              <a-spin v-if="fetching" slot="notFoundContent" size="small" tip="加载中..."></a-spin>
              <a-select
                style="width: 260px"
                v-model="search_form.manufactor_id"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @focus="() => getSelectList()"
                :not-found-content="fetching ? undefined : null"
                placeholder="请选择品牌"
                @change="getSeries"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" tip="加载中..."></a-spin>
                <a-select-option v-for="item in brands_list" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="车系">
              <a-select
                v-model="search_form.series"
                placeholder="请选择车系"
                style="width: 260px"
                :disabled="!search_form.manufactor_id"
              >
                <a-select-option :value="item.id" v-for="item in seriesSelectList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="flex-end">
            <a-button
              class="mr-10"
              icon="redo"
              @click="resetSearch"
            >
              重置
            </a-button>
            <a-button
              class="mr-10"
              type="primary"
              icon="search"
              @click="bindSearch"
            >
              搜索
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-table
          :columns="template_columns"
          :data-source="template_list"
          :pagination="false"
          :rowKey="(record) => record.id"
          :row-selection="rowSelection"
        >
        </a-table>
        <base-pagination
          :currentPage="pagination.current"
          :pageSize="pagination.page_size"
          :total="pagination.total"
          @onChange="handleTableChange"
          @onShowSizeChange="handleTableChange"
          :options="['10', '30', '50']"
        >
        </base-pagination>
      </div>
    </a-modal>

    <!-- 已选择的模板 -->
    <a-modal
      v-model="listModal"
      title="已选择模板"
      width="40%"
      :footer="null"
      @cancel="handlelistModalCancel"
    >
      <div>
        <p v-for="(item, itemIndex) in module_name_list" :key="itemIndex">
          {{ item.module_name ? item.module_name : item.moduleName ? item.moduleName : '' }}</p>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import _https from '@/api/KPI_list'
import request from '@/api/assignment_manage'
import _api from '@/api/dictionaries'

export default {
  name: 'actKpiEdit',
  data () {
    return {
      activity_name: this.$route.query.activity_name || '',
      activity_id: this.$route.query.activity_id || '',
      principal_id: undefined,
      kpi_id: this.$route.query.id || '',
      formItemLayout: {
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 9
        }
      },
      form: {
        id: '',
        date_value: [],
        activitiesList: [
          {
            name: '',
            value: null,
            type: 1
          }
        ],
        dealerList: [
          {
            name: '',
            value: null,
            type: 2
          }
        ],
        modal_checked: true,
        open_type: 0
      },
      indicatorsList: [
        {
          id: '视频作品数',
          name: '视频作品数'
        },
        {
          id: '视频平均播放量',
          name: '视频平均播放量'
        },
        {
          id: '视频平均点赞量',
          name: '视频平均点赞量'
        },
        {
          id: '视频爆款(10w)作品数',
          name: '视频爆款(10w)作品数'
        },
        {
          id: '视频爆款(100w)作品数',
          name: '视频爆款(100w)作品数'
        },
        {
          id: '直播场次(60min)',
          name: '直播场次(60min)'
        },
        {
          id: '直播有效场次(90min)',
          name: '直播有效场次(90min)'
        },
        {
          id: '直播平均观看',
          name: '直播平均观看'
        },
        {
          id: '直播平均点赞',
          name: '直播平均点赞'
        },
        {
          id: '帐号粉丝量',
          name: '帐号粉丝量'
        }
      ],
      checked: false,
      rules: {
        name: [
          { required: true, message: '请输入KPI名称', trigger: 'blur' },
          { max: 30, message: 'KPI名称字数限制30字内', trigger: 'blur' }
        ],
        date_value: [
          { required: true, message: '请选择过期时间', trigger: 'change' }
        ],
        open_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
      },
      templateVisible: false,
      template_columns: [
        {
          dataIndex: 'module_name',
          title: '模板名称'
        },
        {
          dataIndex: 'module_tag_name',
          title: '分类'
        },
        {
          dataIndex: 'manufactor',
          title: '品牌'
        },
        {
          dataIndex: 'series',
          title: '车系'
        },
        {
          dataIndex: 'creator',
          title: '创作人'
        },
      ],
      template_list: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      search_form: {
        manufactor_id: undefined
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      fetching: false,
      brands_list: [],
      seriesSelectList: [],
      module_tag_list: [],
      creator_list: [],
      selectedRowKeys: [],
      listModal: false,
      module_name_list: [],
      selectedRows: [],
      template: [],
      status: 0
    }
  },
  mounted () {
    this.getSelectList()
    this.getmoduleTagList()
    // this.principal_id = this.$route.query.principal_id || undefined
    if (this.kpi_id) {
      this.getKpiData()
    }
    this.getTemplateList()
  },
  methods: {
    getKpiData () {
      _https.getKpiData(this.kpi_id).then(res => {
        if (res.code == 200) {
          this.$set(this.form, 'id', res.data.id)
          this.$set(this.form, 'name', res.data.name)
          let date_value = []
          date_value[0] = res.data.start_time || ''
          date_value[1] = res.data.end_time || ''
          this.$set(this.form, 'start_time', res.data.start_time)
          this.$set(this.form, 'end_time', res.data.end_time)
          this.$set(this.form, 'date_value', date_value)
          let list = res.data.standard_list || []
          let activitiesList = []
          let dealerList = []
          list.forEach(item => {
            if (item.type == 1) {
              activitiesList.push(item)
            }
            if (item.type == 2) {
              dealerList.push(item)
            }
          })
          this.$set(this.form, 'activitiesList', activitiesList)
          this.$set(this.form, 'dealerList', dealerList)
          this.$set(this, 'selectedRowKeys', res.data.module_ids || [])
          this.$set(this, 'template', res.data.module_ids || [])
          this.$set(this.form, 'modal_checked', true)
          this.$set(this.form, 'open_type', res.data.module_ids.length ? 1 : 0)
          this.$set(this.form, 'modal_checked', true)
          if (res.data.module_ids.length) {
            this.getModuleMameList(res.data.module_ids)
          }
        } else {
          this.$message.error(res.message || '获取详情失败')
        }
      })
    },
    onChangeDate (dates) {
      // console.log(dates)
      this.form.date_value = dates
      this.form.start_time = dates[0] + ' 00:00:00'
      this.form.end_time = dates[1] + ' 23:59:59'
    },
    addBtn (type) {
      switch (type) {
        case 0:
          this.form.activitiesList.push({
            name: '',
            value: null,
            type: 1
          })
          break
        case 1:
          this.form.dealerList.push({
            name: '',
            value: null,
            type: 2
          })
          break
      }
    },
    removeItem (type, index) {
      switch (type) {
        case 0:
          this.form.activitiesList.splice(index, 1)
          break
        case 1:
          this.form.dealerList.splice(index, 1)
          break
      }
    },
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if ((!this.form.activitiesList.length || !this.form.activitiesList[0].value || !this.form.activitiesList[0].name) && (!this.form.dealerList.length || !this.form.dealerList[0].value || !this.form.dealerList[0].name)) {
            this.$message.error('请设置KPI指标')
            return false
          }
          if (this.form.open_type == 1 && !this.selectedRowKeys.length) {
            this.$message.error('请设选择活动模板')
            return false
          }
          let params = {
            creator: this.kpi_id ? undefined : this.$router.app.user.realname,
            modifier: this.kpi_id ? this.$router.app.user.realname : undefined,
            id: this.form.id,
            activity_id: this.activity_id,
            name: this.form.name, // kpi名称
            start_time: this.form.start_time, // 考核开始时间
            end_time: this.form.end_time, // 考核结束时间
            standard_list: [], // 指标列表
            module_ids: this.form.open_type == 1 ? this.selectedRowKeys : [] // 指标列表
          }
          let array = _.concat(this.form.dealerList, this.form.activitiesList)
          let standard_list = _.remove(array, function (item) {
            return item.value && item.name
          })
          params.standard_list = standard_list
          _https.putKpiForm(params).then(res => {
            if (res.code == 200) {
              if (this.checked) {
                this.$set(this.form, 'date_value', [])
                this.$set(this.form, 'id', '')
                this.$set(this.form, 'name', '')
                this.$set(this.form, 'activitiesList', [{
                  name: '',
                  value: null,
                  type: 1
                }])
                this.$set(this.form, 'dealerList', [
                  {
                    name: '',
                    value: null,
                    type: 2
                  }
                ])
                this.checked = false
                this.kpi_id = ''
              } else {
                this.$router.push({
                  name: 'actManage:kpiList',
                  query: {
                    activity_name: this.activity_name,
                    activity_id: this.activity_id,
                    principal_id: this.principal_id,
                  }
                })
              }
              this.$message.success('KPI提交成功')
            } else {
              this.$message.error(res.message || 'KPI提交失败')
            }
          })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    handelCancel () {
      let that = this
      this.$confirm({
        title: '表单数据还未保存离开数据将会丢失，是否确认离开？',
        okText: '确定',
        cancelText: '取消',
        onOk () {
          that.$router.push({
            name: 'actManage:kpiList',
            query: {
              activity_name: that.activity_name,
              activity_id: that.activity_id
            }
          })
        }
      })
    },
    onChange (e) {
      // console.log(`checked = ${e.target.checked}`)
      this.checked = e.target.checked
    },
    changeModalChecked () {
      this.form.open_type = 0
    },
    getTemplateList () {
      let params = {
        module_name: this.search_form.module_name,
        module_tag_id: this.search_form.module_tag_id,
        manufactor_id: this.search_form.manufactor_id || this.principal_id,
        // manufactor_name: this.search_form.manufactor_name,
        series: this.search_form.series,
        creator: this.search_form.creator,
        page_size: this.pagination.page_size,
        current: this.pagination.current,
      }
      _https.getTemplateList(params).then(res => {
        if (res.code == '200') {
          this.template_list = res.data.list || []
          this.pagination.total = res.data.pagination.total || 0
        } else {
          this.template_list = []
          this.pagination.total = 0
          this.$message.error(res.message || '获取模板列表失败')
        }
      })
    },
    getModuleMameList (module_ids) {
      let ids = Array.isArray(module_ids) ? module_ids.map(item => item).join(',') : ''
      _https.getModuleMameList(ids).then(res => {
        if (res.code == '200') {
          this.selectedRows = res.data || []
        } else {
          this.selectedRows = []
          this.$message.error(res.message || '获取模板列表失败')
        }
      })
    },
    handleOktTemplateVisible () {
      // console.log(this.selectedRowKeys, this.selectedRows)
      this.templateVisible = false
      this.template = this.selectedRowKeys
    },
    handleTableChange (current, page_size) {
      this.pagination.current = current
      this.pagination.page_size = page_size || 10
      this.getTemplateList()
    },
    getmoduleTagList () {
      _https.getmoduleTagList()
        .then((res) => {
          // console.log(res)
          this.module_tag_list = res.data || []
        })
    }, //分类
    getSelectList () {
      this.fetching = true
      _api.getBrandNewList()
        .then((res) => {
          // console.log(res)
          this.brands_list = res.list || []
          this.fetching = false
          // this.$nextTick(() => {
          //   setTimeout(() => {
          //     this.search_form.manufactor_id = this.principal_id
          //     this.getSeriesList(this.principal_id)
          //   }, 800)
          // })
        })
        .catch(() => {
          this.fetching = false
        })
    }, //品牌数据
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },  // 搜索品牌
    getSeries (value) {
      this.$set(this.search_form, 'series_id', undefined)
      this.seriesSelectList = []
      this.getSeriesList(value)
    },
    getSeriesList (manufactor_id) {
      request.getSeries(manufactor_id).then((res) => {
        if (res.code == 200) {
          this.seriesSelectList = res.data || []
        }
      })
        .catch(() => {
          this.fetching = false
        })
    },//获取车系
    //搜索
    bindSearch () {
      this.pagination.current = 1
      this.pagination.page_size = 10
      this.getTemplateList()
    },
    //重置
    resetSearch () {
      this.search_form = {}
      this.pagination.current = 1
      this.pagination.page_size = 10
      this.getTemplateList()
    },
    onSelectTemplate () {
      this.status = 1
      this.templateVisible = true
    },
    handleCancel () {
      this.search_form = {}
      this.templateVisible = false
      // this.template_list = []
      this.pagination = {
        page_size: 10,
        current: 1,
        total: 0
      }
      this.template = this.selectedRowKeys
      if (this.status != 2) {
        this.selectedRowKeys = []
      }
    },
    handelBtn (type) {
      switch (type) {
        case 1:
          this.listModal = true
          this.module_name_list = this.selectedRows
          break
        case 2:
          this.status = 2
          this.templateVisible = true
          this.selectedRowKeys = this.template
          break
        case 3:
          this.selectedRows = []
          this.selectedRowKeys = []
          break
      }
    },
    handlelistModalCancel () {
      this.listModal = false
      this.module_name_list = []
    },
    changeOpen_type () {
      // console.log(this.form.open_type)
      if (this.form.open_type == 0) {
        this.selectedRowKeys = []
      }
    }
  },
  computed: {
    disabledName () {
      return this.form.activitiesList.map(v => v.name)
    },
    disabledDealer () {
      return this.form.dealerList.map(v => v.name)
    },
    rowSelection () {
      const { selectedRowKeys, selectedRows } = this
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows
          this.selectedRowKeys = selectedRowKeys
        },
      }
    }
  }
}
</script>

<style scoped lang="less">
.page-container {
  padding: 0 !important;
}

.form-model {

  .main-title {
    font-size: 14px;
    margin-left: 86px;
    font-weight: bold;
    color: #333333;
  }

  .main-title1 {
    font-size: 14px;
    margin-left: 66px;
    font-weight: bold;
    color: #333333;
  }
}

.h1_title {
  margin-left: 40px;
  padding-bottom: 40px;
  font-size: 24px;
  color: #333333;
}

.item {
  display: flex;
  align-items: center;
}

.item_box {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  span {
    display: inline-block;
    width: 156px;
    text-align: right;
    padding-right: 10px;
  }
}

.item_box1 {
  span {
    width: 100px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
</style>
